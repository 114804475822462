<template>
    <div class="container" style="background-color: #fff;">
        <div class="row">
            <div class="col-12 mb-3">
                <router-link
                    class="tab_btn"
                    v-for="(t, tk) in tabs"
                    :class="nowtab == t ? 'active' : ''"
                    :to="`#${t}`"
                    :key="`tab_${tk}`"
                    >{{ $store.state.language.about[t] }}
                </router-link>
            </div>
            <transition-group name="slide-fade" class="mb-5">
                <div
                    class="col-12 tab"
                    v-for="t in tabs"
                    :key="`${t}`"
                    v-show="nowtab == t"
                >
                    <row v-model="$store.state.data.about[t]"></row>
                </div>
            </transition-group>
        </div>
    </div>
</template>
<style lang="scss">
.tab {
    min-height: 80vh;
}
</style>
<script>
import row from "../components/row.vue";
export default {
    data() {
        return {
            tabs: ["history", "vision", "application", "support", "promise"],
            nowtab: "history"
        };
    },
    mounted() {
        this.switch_tab();
    },
    watch: {
        "$route.hash"() {
            this.switch_tab();
        }
    },
    methods: {
        switch_tab() {
            this.nowtab = !this.$route.hash
                ? "history"
                : this.$route.hash.substr(1);
        }
    },
    components: { row }
};
</script>
